import axios from 'axios'
import type { AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { storeToRefs } from 'pinia'
import { Logger } from 'tslog'
import type { ILogObj } from 'tslog'
import { useAccountStore } from '@/vantool/stores/account'

declare module 'axios' {
  interface AxiosRequestConfig {
    skipJWTRefresh?: boolean
  }
}

const log = new Logger<ILogObj>({ name: 'api' })

const api = axios.create({
  baseURL: '/api',
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken'
})

const getErrorMessageFromResponse = (response: AxiosResponse) => {
  if (response.data.detail) {
    if (response.data.detail === 'string') {
      return response.data.detail
    }
    if (Array.isArray(response.data.detail)) {
      return response.data.detail.map((detail: any) => detail.msg).join(' ')
    }
    return null
  }

  if (response.data.error) {
    return response.data.error
  }
  if (response.data.errors) {
    return response.data.errors.join(' ')
  }
  if (Array.isArray(response.data)) {
    return response.data.join(' ')
  }
  if (response.status === 413) {
    return 'Content too large'
  }

  return null
}

api.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    if (!config.withCredentials) {
      const { accessToken } = storeToRefs(useAccountStore())
      if (accessToken.value) {
        config.headers['X-Access-Token'] = `Bearer ${accessToken.value}`
      }
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config
    if (
      originalRequest &&
      !originalRequest.withCredentials &&
      [401, 403].includes(error.response?.status) &&
      !originalRequest._sent &&
      !originalRequest.skipJWTRefresh
    ) {
      originalRequest._sent = true
      await useAccountStore().requestAccessTokenRefresh()
      return api(originalRequest)
    }

    if (error.response?.data) {
      error.message = getErrorMessageFromResponse(error.response) || error.message
    } else if (error.code === 'ERR_NETWORK') {
      error.message = `It looks like you're offline. Please check your internet connection.`
    } else {
      error.message = `The request could not be made: ${error.message}`
    }

    log.error(error.message)
    return Promise.reject(error)
  }
)

export default api
