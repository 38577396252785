import { Logger } from 'tslog'

interface ILogObj {
  messages: any[]
}

const logger = new Logger<ILogObj>({
  name: 'Vantool',
  maskValuesOfKeys: ['password', 'accessToken', 'access_token', 'refreshToken', 'refresh_token'],
  argumentsArrayName: 'messages'
})

export default function createLogger(name: string) {
  return logger.getSubLogger({ name })
}
